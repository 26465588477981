<template>
  <div class="container mt-5">
    <HeaderMaridador />
    <p class="text-light text-kroon">
      Corona tus ocasiones con las mejores tablas de quesos Kroon.
      Te ayudamos a crear combinaciones increíbles y sorprender a tus invitados.
      ¡Kroon creado para ti!
    </p>

    <form @submit.prevent="calificaMiCreacion" class="form-group">
      <div class="mb-4">
        
        <!-- Queso seleccionado -->
        <div v-if="quesoSeleccionado" class="queso-seleccionado-container mb-3">
          <div class="queso-seleccionado">
            <img :src="obtenerImagenQueso(quesoSeleccionado)" :alt="quesoSeleccionado" class="queso-imagen">
            <div class="queso-info">
              <h3>{{ obtenerNombreQueso(quesoSeleccionado) }}</h3>
              <p>{{ obtenerDescripcionQueso(quesoSeleccionado) }}</p>
            </div>
          </div>
        </div>

        <!-- Botón para cambiar selección -->
        <button @click.prevent="abrirSelectorQuesos" class="btn btn-outline-warning w-100 custom-btn">
          {{ quesoSeleccionado ? 'Cambiar queso' : 'Selecciona tu queso favorito' }}
        </button>

        <div v-if="!quesoSeleccionado && intentoEnvio" class="invalid-feedback d-block">
          Por favor selecciona un queso antes de continuar.
        </div>
      </div>

      <div v-if="quesoSeleccionado">
        <p class="text-center mb-4">Ahora selecciona los ingredientes que deseas maridar con tu Queso {{ quesoSeleccionado }}</p>

        <!-- Frutos secos/nueces -->
        <div class="mb-4">
          <input v-model="crujiente" class="form-control" 
            :placeholder="placeholderActivo === 'frutosSecos' ? 'Escribe o haz click en nuestras sugerencias:' : 'Frutos secos/nueces...'" 
            @focus="activarPlaceholder('frutosSecos')" 
            @blur="desactivarPlaceholder"
            @input="mostrarSugerenciasHandler('frutosSecos')"
          />
          <p v-if="mostrarSugerencias === 'frutosSecos'" class="lista-sugerencias">
            <span v-for="(sugerencia, index) in sugerenciasFrutosSecos" :key="index">
              <a @click.prevent="agregarSugerencia('crujiente', sugerencia)" href="#">
                {{ sugerencia }}
              </a>
              <span v-if="index < sugerenciasFrutosSecos.length - 1">, </span>
            </span>
          </p>
        </div>

        <!-- Panes/galletas -->
        <div class="mb-4">
          <input v-model="panesGalletas" class="form-control" 
            :placeholder="placeholderActivo === 'panesGalletas' ? 'Escribe o haz click en nuestras sugerencias:' : 'Panes y galletas...'" 
            @focus="activarPlaceholder('panesGalletas')" 
            @blur="desactivarPlaceholder"
            @input="mostrarSugerenciasHandler('panesGalletas')"
          />
          <p v-if="mostrarSugerencias === 'panesGalletas'" class="lista-sugerencias">
            <span v-for="(sugerencia, index) in sugerenciasPanesGalletas" :key="index">
              <a @click.prevent="agregarSugerencia('panesGalletas', sugerencia)" href="#">
                {{ sugerencia }}
              </a>
              <span v-if="index < sugerenciasPanesGalletas.length - 1">, </span>
            </span>
          </p>
        </div>

        <!-- Algo dulce -->
        <div class="mb-4">
          <input v-model="dulce" class="form-control" 
            :placeholder="placeholderActivo === 'dulce' ? 'Escribe o haz click en nuestras sugerencias:' : 'Algo dulce...'" 
            @focus="activarPlaceholder('dulce')" 
            @blur="desactivarPlaceholder"
            @input="mostrarSugerenciasHandler('dulce')"
          />
          <p v-if="mostrarSugerencias === 'dulce'" class="lista-sugerencias">
            <span v-for="(sugerencia, index) in sugerenciasDulce" :key="index">
              <a @click.prevent="agregarSugerencia('dulce', sugerencia)" href="#">
                {{ sugerencia }}
              </a>
              <span v-if="index < sugerenciasDulce.length - 1">, </span>
            </span>
          </p>
        </div>

        <!-- Algo vegetal -->
        <div class="mb-4">
          <input v-model="vegetal" class="form-control" 
            :placeholder="placeholderActivo === 'vegetal' ? 'Escribe o haz click en nuestras sugerencias:' : 'Algo vegetal...'" 
            @focus="activarPlaceholder('vegetal')" 
            @blur="desactivarPlaceholder"
            @input="mostrarSugerenciasHandler('vegetal')"
          />
          <p v-if="mostrarSugerencias === 'vegetal'" class="lista-sugerencias">
            <span v-for="(sugerencia, index) in sugerenciasVegetal" :key="index">
              <a @click.prevent="agregarSugerencia('vegetal', sugerencia)" href="#">
                {{ sugerencia }}
              </a>
              <span v-if="index < sugerenciasVegetal.length - 1">, </span>
            </span>
          </p>
        </div>

        <!-- Charcutería -->
        <div class="mb-4">
          <input v-model="charcuteria" class="form-control" 
            :placeholder="placeholderActivo === 'charcuteria' ? 'Escribe o haz click en nuestras sugerencias:' : 'Charcutería...'" 
            @focus="activarPlaceholder('charcuteria')" 
            @blur="desactivarPlaceholder"
            @input="mostrarSugerenciasHandler('charcuteria')"
          />
          <p v-if="mostrarSugerencias === 'charcuteria'" class="lista-sugerencias">
            <span v-for="(sugerencia, index) in sugerenciasCharcuteria" :key="index">
              <a @click.prevent="agregarSugerencia('charcuteria', sugerencia)" href="#">
                {{ sugerencia }}
              </a>
              <span v-if="index < sugerenciasCharcuteria.length - 1">, </span>
            </span>
          </p>
        </div>

        <!-- Bebida -->
        <div class="mb-4">
          <input v-model="bebida" class="form-control" 
            :placeholder="placeholderActivo === 'bebida' ? 'Escribe o haz click en nuestras sugerencias:' : 'Bebida...'" 
            @focus="activarPlaceholder('bebida')" 
            @blur="desactivarPlaceholder"
            @input="mostrarSugerenciasHandler('bebida')"
          />
          <p v-if="mostrarSugerencias === 'bebida'" class="lista-sugerencias">
            <span v-for="(sugerencia, index) in sugerenciasBebida" :key="index">
              <a @click.prevent="agregarSugerencia('bebida', sugerencia)" href="#">
                {{ sugerencia }}
              </a>
              <span v-if="index < sugerenciasBebida.length - 1">, </span>
            </span>
          </p>
        </div>

        <p class="text-center mb-4">¿Quieres agregar algo más?</p>
        <input v-model="extras" class="form-control" placeholder="Tu creatividad es el límite..." />
        
        <!-- Movemos el botón dentro del div condicionado por quesoSeleccionado -->
        <button type="submit" class="btn btn-warning btn-block" :disabled="cargando">
          <span v-if="cargando" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span v-if="!cargando">
            <SparklesIcon class="sparkles-icon"/>
            Evalúa mi creación
          </span>
        </button>
      </div>

      <!-- Liga a los términos y condiciones (fuera del div condicional) -->
      <p class="text-center mt-3">
        <a @click.prevent="mostrarTerminos" href="#" class="terminos-link">Términos y condiciones</a>
      </p>

      <!-- Modal de términos y condiciones -->
      <div v-if="mostrarModalTerminos" class="modal-backdrop">
        <div class="modal-content">
          <span @click="cerrarModalTerminos" class="close-button">&times;</span>
          <iframe src="/terminos" style="width: 100%; height: 800px; border: none;"></iframe>
        </div>
      </div>

      <!-- Modal de selección de quesos -->
      <div v-if="mostrarModalQuesos" class="modal-quesos-backdrop">
        <div class="modal-quesos">
          <div class="modal-header">
            <h4>Selecciona tu queso favorito</h4>
            <span @click="cerrarSelectorQuesos" class="close-button">&times;</span>
          </div>
          <div class="quesos-lista">
            <div v-for="queso in quesos" 
                 :key="queso.id" 
                 class="queso-item"
                 :class="{'selected': quesoSeleccionado === queso.id}"
                 @click="seleccionarQueso(queso.id)">
              <div class="queso-imagen-container">
                <img :src="queso.imagen" :alt="queso.nombre">
              </div>
              <div class="queso-detalles">
                <h3>{{ queso.nombre }}</h3>
                <p>{{ queso.descripcion }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import { SparklesIcon } from '@heroicons/vue/outline';
import HeaderMaridador from './HeaderMaridador.vue';

export default {
  components: {
    HeaderMaridador,
    SparklesIcon
  },
  data() {
    return {
      crujiente: '',
      panesGalletas: '',
      dulce: '',
      vegetal: '',
      charcuteria: '',
      bebida: '',
      quesoSeleccionado: '',
      extras: '',
      cargando: false,
      intentoEnvio: false,
      mostrarSugerencias: '',
      mostrarModalTerminos: false,
      placeholderActivo: null,
      mostrarModalQuesos: false,

      ingredientesOriginales: null, // Para guardar los ingredientes originales

      // Sugerencias
      sugerenciasFrutosSecos: ['Almendras', 'Pasas', 'Avellanas', 'Pistachos', 'Nuez pecana', 'Castañas', 'Nuez de la india', 'Piñones', 'Macadamias'],
      sugerenciasPanesGalletas: ['Baguette', 'Pan de nueces y pasas', 'Pan de centeno', 'Ciabatta', 'Pan con aceitunas y orégano', 'Pan de masa madre', 'Galletas saladas', 'Galletas de avena', 'Pretzels'],
      sugerenciasDulce: ['Uvas', 'Fresas', 'Higos', 'Manzana', 'Pera', 'Zarzamoras', 'Mango', 'Toronja', 'Naranja', 'Mora azul', 'Mermeladas (fresa, durazno, etc)'],
      sugerenciasVegetal: ['Aceitunas', 'Tomates cherry', 'Pepino', 'Zanahorias', 'Apio', 'Pimiento', 'Jitomate deshidratado', 'Cebolla caramelizada', 'Pepinillos'],
      sugerenciasCharcuteria: ['Jamón serrano', 'Salami', 'Chorizo', 'Mortadela', 'Salchicha', 'Pastrami', 'Prosciutto', 'Pepperoni', 'Lomo embuchado', 'Salchichón'],
      sugerenciasBebida: ['Vino tinto', 'Vino blanco', 'Vino rosado', 'Vino espumoso', 'Cerveza clara', 'Cerveza oscura', 'Café', 'Mezcal', 'Limonada', 'Té', 'Agua de sabor'],

      quesos: [
        {
          id: 'Gouda',
          nombre: 'Gouda',
          descripcion: 'Queso suave y cremoso con un sabor dulce y nueces. Perfecto para cualquier ocasión.',
          imagen: '/gouda/gouda-pack.jpg'
        },
        {
          id: 'Edam',
          nombre: 'Edam',
          descripcion: 'Queso semi-duro con un sabor suave y ligeramente salado. Ideal para tablas de quesos.',
          imagen: '/edam/edam-pack.jpg'
        },
        {
          id: 'Maasdam',
          nombre: 'Maasdam',
          descripcion: 'Queso con ojos y sabor dulce a nueces. Excelente para bocadillos y tablas.',
          imagen: '/maasdam/maasdam-pack.jpg'
        },
        {
          id: 'Procesado-ahumado-natural',
          nombre: 'Procesado ahumado natural',
          descripcion: 'Queso con un delicado sabor ahumado natural. Perfecto para derretir.',
          imagen: '/ahumado-natural/procesado-pack.jpg'
        },
        {
          id: 'Procesado-ahumado-finas-hierbas',
          nombre: 'Procesado ahumado finas hierbas',
          descripcion: 'Queso ahumado con finas hierbas que aportan un sabor único.',
          imagen: '/ahumado-finas-hierbas/procesado-fh-pack.jpg'
        }
      ]
    };
  },
  mounted() {
    // Cargar los ingredientes del store si existen
    const ingredientes = this.$store.getters.ingredientes;
    if (ingredientes) {
      this.quesoSeleccionado = ingredientes.quesoSeleccionado;
      this.crujiente = ingredientes.crujiente;
      this.panesGalletas = ingredientes.panesGalletas;
      this.dulce = ingredientes.dulce;
      this.vegetal = ingredientes.vegetal;
      this.charcuteria = ingredientes.charcuteria;
      this.bebida = ingredientes.bebida;
      this.extras = ingredientes.extras;

      // Guardar los ingredientes originales para compararlos después
      this.ingredientesOriginales = JSON.stringify(ingredientes);
    }
  },
  methods: {
    mostrarSugerenciasHandler(categoria) {
      this.mostrarSugerencias = categoria;
    },
    agregarSugerencia(campo, sugerencia) {
      if (!this[campo].includes(sugerencia)) {
        if (this[campo].length > 0) {
          this[campo] += ', ' + sugerencia;
        } else {
          this[campo] = sugerencia;
        }
      }
    },
    calificaMiCreacion() {
      this.intentoEnvio = true;

      if (!this.quesoSeleccionado) {
        return;
      }

      const ingredientes = {
        quesoSeleccionado: this.quesoSeleccionado,
        crujiente: this.crujiente,
        panesGalletas: this.panesGalletas,
        dulce: this.dulce,
        vegetal: this.vegetal,
        charcuteria: this.charcuteria,
        bebida: this.bebida,
        extras: this.extras
      };

      // Verificar si los ingredientes han cambiado
      if (this.ingredientesOriginales === JSON.stringify(ingredientes)) {
        // Si no han cambiado, simplemente redirigir al resultado anterior
        this.$router.push({ name: 'resultado' });
        return;
      }

      // Si los ingredientes han cambiado, continuar con la solicitud al backend
      this.cargando = true;

      // Guardar los nuevos ingredientes en el store
      this.$store.dispatch('actualizarIngredientes', ingredientes);

      fetch(`${process.env.VUE_APP_BACKEND_URL}/califica`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(ingredientes)
      })
        .then(response => response.json())
        .then(data => {
  // No es necesario manipular el JSON como texto
  this.$store.dispatch('actualizarResultado', data); // Directamente usar 'data'
  this.$router.push({ name: 'resultado' });
})
        .catch(error => {
          console.error('Error:', error);
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    mostrarTerminos() {
      this.mostrarModalTerminos = true;
    },
    cerrarModalTerminos() {
      this.mostrarModalTerminos = false;
    },
    activarPlaceholder(campo) {
      this.placeholderActivo = campo;
      this.mostrarSugerenciasHandler(campo);
    },
    desactivarPlaceholder() {
      this.placeholderActivo = null;
    },
    obtenerNombreQueso(id) {
      const queso = this.quesos.find(q => q.id === id);
      return queso ? queso.nombre : '';
    },
    obtenerImagenQueso(id) {
      const queso = this.quesos.find(q => q.id === id);
      return queso ? queso.imagen : '';
    },
    obtenerDescripcionQueso(id) {
      const queso = this.quesos.find(q => q.id === id);
      return queso ? queso.descripcion : '';
    },
    abrirSelectorQuesos() {
      this.mostrarModalQuesos = true;
    },
    cerrarSelectorQuesos() {
      this.mostrarModalQuesos = false;
    },
    seleccionarQueso(id) {
      this.quesoSeleccionado = id;
      this.cerrarSelectorQuesos();
    }
  }
};
</script>

<style scoped>
.sparkles-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.queso-seleccionado-container {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.queso-seleccionado {
  display: flex;
  align-items: center;
  padding: 0.6rem;
}

.queso-imagen {
  width: 45%;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
}

.queso-info {
  flex: 1;
  padding-left: 1rem;
}

.queso-info h3 {
  color: #fff;
  margin-bottom: 0.5rem;
}

.queso-info p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  margin-bottom: 0;
}

.modal-quesos-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-quesos {
  background: #1a1a1a;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 8px;
  color: white;
}

.modal-header {
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quesos-lista {
  padding: 0.8rem;
}

.queso-item {
  display: flex;
  margin-bottom: 1rem;
  padding: 0.6rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.queso-item:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.05);
}

.queso-item.selected {
  border-color: #ffc107;
  background: rgba(255, 193, 7, 0.1);
}

.queso-imagen-container {
  width: 40%;
  padding-right: 0.6rem;
}

.queso-imagen-container img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
}

.queso-detalles {
  width: 60%;
  padding-left: 0.6rem;
}

.close-button {
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  padding: 0.5rem;
}

.close-button:hover {
  opacity: 0.8;
}

.custom-btn {
  color: #f0d29f;
  border-color: #f0d29f;
}

.custom-btn:hover {
  color: black !important;
  background-color: #f0d29f !important;
  border-color: #f0d29f !important;
}
</style>
