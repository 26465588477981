<template>
  <div class="container mt-5 text-center">
    <h1 class="text-light">Mi Tabla de Quesos</h1>

    <!-- Mostrar la imagen generada usando directamente el uuid o desde Strapi -->
    <div v-if="imagenUrl">
      <img :src="imagenUrl" alt="Tabla generada" class="img-fluid mt-4" style="border-radius: 10px;">
    </div>

    <p class="leyenda-ia">Generado en kroonmaridaje.com con IA</p>

    <!-- Lista de ingredientes seleccionados -->
    <div v-if="ingredientes && tieneIngredientes" class="ingredientes-lista mt-3">
      <h5>Ingredientes seleccionados:</h5>
      <ol>
        <template v-for="(valor, clave) in ingredientesFiltrados" :key="clave">
          <li>{{ traducirIngrediente(valor) }}</li>
        </template>
      </ol>
    </div>

    <!-- Llamada a la acción -->
      <div class="row mt-3">
        <div class="col-md-6">
          <button @click="irFormulario" class="btn btn-warning w-100">
            <SparklesIcon class="sparkles-icon"/>
            Crear
          </button>
        </div>
        <div class="col-md-6">
          <button @click="compartirTabla" class="btn btn-warning w-100">Reenviar <i class="fa-regular fa-share-from-square"></i></button>
        </div>
      </div>
  </div>
</template>

<script>

import { SparklesIcon } from '@heroicons/vue/outline';

export default {
  props: ['uuid'],
  data() {
    return {
      imagenUrl: '',
      ingredientes: {},
      titulo: 'Mi tabla de quesos'
    };
  },
  mounted() {
    // Cargar los datos desde Strapi usando el UUID
    this.obtenerIngredientesYImagen();
  },
  components: {
    SparklesIcon
  },
  computed: {
    tieneIngredientes() {
      return Object.values(this.ingredientes).some(valor => valor);
    },
    ingredientesFiltrados() {
      return Object.fromEntries(
        Object.entries(this.ingredientes)
          .filter(([_, valor]) => valor && valor !== 'None provided')
      );
    }
  },
  methods: {
    irFormulario() {
      this.$router.push({ name: 'formulario' });
    },
    compartirTabla() {
      const url = `${window.location.origin}/tabla/${this.uuid}`;
      
      if (navigator.share) {
        navigator.share({
          title: 'Mi tabla de quesos',
          text: '¡Mira la tabla de quesos que hice con IA en kroonmaridaje.com!',
          url: url
        }).then(() => {
          console.log('Tabla compartida con éxito');
        }).catch(err => {
          console.log('Error al compartir:', err);
        });
      } else {
        alert('Tu navegador no soporta la funcionalidad de compartir.');
      }
    },
    obtenerIngredientesYImagen() {
      // Llamada a la API de Strapi para obtener los ingredientes de la tabla
      const headers = new Headers({
        'Authorization': `Bearer ${process.env.VUE_APP_STRAPI_READ_TOKEN}`,
        'Content-Type': 'application/json'
      });

      fetch(`${process.env.VUE_APP_API_URL}/tablas?filters[uuid_imagen][$eq]=${this.uuid}&populate=imagenes`, { headers })
        .then(response => response.json())
        .then(data => {
          if (data && data.data && data.data.length > 0) {
            const tabla = data.data[0];

            // Actualizar el título con el nombre de la tabla si existe
            if (tabla.attributes?.nombre) {
              this.titulo = tabla.attributes.nombre;
            }

            // Verificar si los ingredientes existen antes de asignarlos
            if (tabla.ingredientes) {
              this.ingredientes = tabla.ingredientes;
            } else {
              console.error('No se encontraron ingredientes para el UUID proporcionado');
            }

            // Verificar si hay imágenes disponibles y asignamos la URL correcta desde Strapi
            const imagenes = tabla.imagenes?.[0]; // Tomar la primera imagen si está disponible
            if (imagenes && imagenes.formats && imagenes.formats.large) {
              this.imagenUrl = `${process.env.VUE_APP_IMAGE_URL}${imagenes.formats.large.url}`; // Tomar el formato "large"
            } else if (imagenes && imagenes.url) {
              // Si no existe el formato 'large', usar la imagen original
              this.imagenUrl = `${process.env.VUE_APP_IMAGE_URL}${imagenes.url}`;
            } else {
              console.error('No se encontraron imágenes para el UUID proporcionado');
            }
          } else {
            console.error('No se encontraron datos para el UUID proporcionado');
          }
        })
        .catch(error => {
          console.error('Error al obtener los datos de Strapi:', error);
        });
    },
    traducirIngrediente(ingrediente) {
      // Diccionario de traducciones
      const traducciones = {
        'White wine': 'Vino blanco',
        'Red wine': 'Vino tinto',
        'Beer': 'Cerveza',
        // Añade más traducciones según necesites
      };
      
      return traducciones[ingrediente] || ingrediente;
    }
  },
  metaInfo() {
    return {
      title: this.titulo,
      meta: [
        { property: 'og:title', content: this.titulo },
        { property: 'og:description', content: '¡Mira la tabla de quesos que hice con IA en kroonmaridaje.com!' },
        { property: 'og:image', content: this.imagenUrl },
        { property: 'og:url', content: `${window.location.origin}/tabla/${this.uuid}` },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: this.titulo },
        { name: 'twitter:description', content: '¡Mira la tabla de quesos que hice con IA en kroonmaridaje.com!' },
        { name: 'twitter:image', content: this.imagenUrl }
      ]
    }
  }
};
</script>

<style scoped>
.sparkles-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.container {
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 10px;
}
.img-fluid {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}
.text-light {
  color: #fff;
}

/* Lista de ingredientes */
.ingredientes-lista {
  text-align: left;
  margin: 0 auto;
  color: #ffffff;
  max-width: 70%;
}

.ingredientes-lista ol {
  margin: 0 auto;
  list-style-type: decimal;
  padding-left: 20px;
  margin-bottom: 30px;
}

.ingredientes-lista li {
  margin-bottom: 0px;
}
.call-to-action {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 20px;
} 

</style>
