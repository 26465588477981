<template>
  <div class="container mt-5">
    <HeaderMaridador />
    <div class="container text-center">
      <h4>Resultado de tu maridaje</h4>
      <div class="ingredientes-seleccionados">
        <p><strong>{{ descripcion }}</strong></p>
      </div>

      <!-- Desplegar 5 coronas, algunas opacas y otras transparentes -->
      <div class="coronas">
        <img 
          v-for="index in 5" 
          :key="index" 
          :src="require(index <= coronas ? '../assets/corona.png' : '../assets/corona-transp.png')" 
          alt="Corona" 
          class="coronas-icon"
          style="max-height: 50px;"
        />
      </div>

      <!-- Mostrar la calificación y resumen -->
      <h2 class="mt-4">{{ rango }}</h2>

      <!-- Enlace para abrir modal de recomendaciones -->
      <a @click.prevent="mostrarModalRecomendaciones" class="recomendaciones-link">            
        <SparklesIcon class="sparkles-icon-small"/>
        Ver recomendaciones de la IA
      </a>
      
      <!-- Modal de recomendaciones -->
      <div v-if="mostrarRecomendaciones" class="modal-backdrop">
        <div class="modal-content">
          <!-- Botón de cierre como cruz "X" -->
          <span @click="cerrarModalRecomendaciones" class="close-button">&times;</span>
          <h3 style="margin: 20px 0">Recomendaciones</h3>
          <div class="modal-body">
            <ul>
              <li class="sugerencia" v-for="(sugerencia, index) in sugerencias" :key="index">{{ sugerencia }}</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Botón de generar o mostrar la imagen generada con IA -->
      <button @click="generarImagenIA" class="btn btn-warning" :disabled="cargandoImagen">
        <span v-if="cargandoImagen" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span v-if="cargandoImagen" style="padding-left: 10px; font-size: 0.8em">{{ mensajeActual }}</span>
        <span v-else>
          <template v-if="imagenGenerada">Ver mi creación</template>
          <template v-else>
            <SparklesIcon class="sparkles-icon"/> Crear mi tabla con IA
          </template>
        </span>
      </button>

      <!-- Botones de navegación -->
      <button @click="editarMaridaje" class="btn btn-secondary mt-3">
        <i class="fa-regular fa-pen-to-square small pe-1"></i>
         Editar maridaje
      </button>

      <!-- Modal de imagen generada -->
      <div v-if="mostrarImagenGenerada" class="modal-backdrop">
        <div class="modal-content">
          <!-- Botón de cierre como cruz "X" -->
          <span @click="cerrarModalImagen" class="close-button">&times;</span>

          <!-- Texto de "¡Qué delicia!" -->
          <h3 class="delicia-titulo">¡Qué delicia!</h3>
          <p class="delicia-subtitulo">Lleva esta tabla a la realidad</p>

          <!-- Enlace para regenerar con ícono y spinner -->
          <a @click="regenerarImagenIA" class="recrear-link">
            <i v-if="!cargandoImagen" class="fas fa-redo-alt"></i>
            <span v-if="cargandoImagen" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span v-if="!cargandoImagen"> Regenerar</span>
            <span v-if="cargandoImagen">{{ mensajeActual }}</span>
          </a>

          <!-- Imagen generada -->
          <div class="modal-body">
            <img :src="imagenUrl" alt="Imagen generada" style="max-width: 100%; border-radius: 0px;">
          </div>

          <!-- Leyenda de IA debajo de la imagen -->
          <p class="leyenda-ia">Generado en kroonmaridaje.com con IA</p>

          <!-- Lista de ingredientes seleccionados (en español) -->
            <div class="ingredientes-lista mt-3" style="display: flex; justify-content: center;">
            <div style="text-align: left;">
            <h5>Ingredientes seleccionados:</h5>
            <ol>
              <li v-if="ingredientesEspanol.quesoSeleccionado">{{ ingredientesEspanol.quesoSeleccionado }}</li>
              <li v-if="ingredientesEspanol.crujiente">{{ ingredientesEspanol.crujiente }}</li>
              <li v-if="ingredientesEspanol.panesGalletas">{{ ingredientesEspanol.panesGalletas }}</li>
              <li v-if="ingredientesEspanol.dulce">{{ ingredientesEspanol.dulce }}</li>
              <li v-if="ingredientesEspanol.vegetal">{{ ingredientesEspanol.vegetal }}</li>
              <li v-if="ingredientesEspanol.charcuteria">{{ ingredientesEspanol.charcuteria }}</li>
              <li v-if="ingredientesEspanol.bebida">{{ ingredientesEspanol.bebida }}</li>
              <li v-if="ingredientesEspanol.extras">{{ ingredientesEspanol.extras }}</li>
            </ol>
          </div>
          </div>

          <!-- Botón de compartir con ícono -->
          <p class="text-center small mb-0">Comparte y etiqueta a @kroonmex</p>
          <button @click="compartirTabla" class="btn btn-warning mt-3">
            Compartir <i class="fa-regular fa-share-from-square"></i>
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { SparklesIcon } from '@heroicons/vue/outline';
import HeaderMaridador from '@/components/HeaderMaridador.vue';

export default {
  components: {
    HeaderMaridador,
    SparklesIcon
  },
  data() {
    return {
      resultado: this.$route.query.resultado || null, 
      ingredientesEspanol: this.$route.query.ingredientes || null,  // Ingredientes en español para mostrar en la tabla
      ingredientesIngles: null, // Ingredientes en inglés para enviar a la API de generación de imágenes
      coronas: 0,
      calificacion: 0,
      rango: '',
      descripcion: '',
      resumen: '',
      sugerencias: [],
      mostrarRecomendaciones: false,
      mostrarImagenGenerada: false,
      cargandoImagen: false,
      imagenUrl: '',
      mensajeActual: 'Iniciando...', // Mensaje que se alternará
      imagenGenerada: false, // Variable para saber si ya se generó la imagen
      yaMostrada: false, // Variable para saber si ya se mostró la imagen una vez
    };
  },
  mounted() {
    const ingredientesEspanol = this.$store.getters.ingredientes;
    const resultado = this.$store.getters.resultado;
    console.log('Resultado:', resultado);
    console.log('Ingredientes en español:', ingredientesEspanol);

    // Asignar ingredientes en español desde el store si están disponibles
    // Asignar ingredientes en español desde el store si están disponibles
    if (ingredientesEspanol) {
      this.ingredientesEspanol = ingredientesEspanol;
    }

    // Asignar resultado y obtener los ingredientes en inglés desde el resultado
    if (resultado) {
      this.coronas = resultado.coronas;
      this.rango = resultado.rango;
      this.calificacion = resultado.calificacion;
      this.resumen = resultado.resumen;
      this.descripcion = resultado.descripcion;
      this.sugerencias = resultado.sugerencias;

      // Ingredientes en inglés del resultado
      if (resultado.ingredients) {
        this.ingredientesIngles = resultado.ingredients;
        console.log('Ingredientes en ingles:', this.ingredientesIngles);
      }
    }
  },
  methods: {
    editarMaridaje() {
      // Redirigir al formulario con los ingredientes prellenados
      this.$router.push({ name: 'formulario' });
    },
    mostrarModalRecomendaciones() {
      this.mostrarRecomendaciones = true;
    },
    cerrarModalRecomendaciones() {
      this.mostrarRecomendaciones = false;
    },
    generarImagenIA() {
    // Verificar si los ingredientes en inglés están disponibles
    if (!this.ingredientesIngles) {
      console.error("Error: No se encontraron los ingredientes en inglés en la respuesta.");
      return;
    }

    // Si la imagen ya fue generada y no se ha vuelto a modificar el estado
    if (this.imagenGenerada && this.yaMostrada) {
      this.mostrarImagenGenerada = true;
      return;
    }

    this.cargandoImagen = true;
    this.imagenGenerada = false;

    const mensajes = [
      "Colocando el queso...",
      "Preparando ingredientes...",
      "Creando composición...",
      "Agregando detalles...",
      "Ajustando la luz...",
      "Últimos ajustes..."
    ];

    let index = 0;
    const intervalId = setInterval(() => {
      this.mensajeActual = mensajes[index];
      index++;
      if (index === mensajes.length) {
        clearInterval(intervalId);
      }
    }, 4000);

    // Llamar al API para generar la imagen
    const datosParaEnviar = {
      queso: this.ingredientesEspanol.quesoSeleccionado,
      ingredientes: this.ingredientesIngles,
      descripcion: this.descripcion,  // Enviar la descripción generada por la IA
      coronas: this.coronas,  // Enviar el número de coronas
      rango: this.rango  // Enviar el rango o nivel generado por la IA
    };

    console.log('Llamando a la API para generar imagen con ingredientes:', this.ingredientesIngles);
    console.log('Datos que se enviarán a la API:', datosParaEnviar);

    this.llamarApiGeneraImagen(datosParaEnviar)
      .then((response) => {
        // Guardar la URL de la imagen y el UUID
        this.imagenUrl = `${process.env.VUE_APP_IMAGE_URL}${response.imagen_url}`;
        this.uuidImagen = response.uuid_imagen;  // Guardar el UUID de la imagen
        this.mostrarImagenGenerada = true;
        this.imagenGenerada = true;
        this.yaMostrada = true;
      })
      .finally(() => {
        this.cargandoImagen = false;
      })
      .catch((error) => {
        console.error("Error generando imagen:", error);
      });
  },

    regenerarImagenIA() {
      this.yaMostrada = false;
      this.cargandoImagen = true;
      this.imagenGenerada = false;

      this.mensajeActual = "Colocando el queso..."; // Reiniciar el mensaje inicial

      // Llamar a la función de generar imagen
      this.generarImagenIA();
    },
    llamarApiGeneraImagen(datos) {
    // Aquí estamos enviando todos los datos juntos (ingredientes, descripción, coronas, rango)
    return fetch(`${process.env.VUE_APP_BACKEND_URL}/genera-imagen-sd`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(datos)  // Enviar todos los datos en el cuerpo de la petición
    }).then(response => response.json());
  },
    cerrarModalImagen() {
      this.mostrarImagenGenerada = false;
    },
    compartirTabla() {
    // Generar la URL de compartir con el UUID de la imagen
    const url = `${window.location.origin}/tabla/${this.uuidImagen}`;

    if (navigator.share) {
      navigator.share({
        title: 'Mi tabla de quesos',
        text: '¡Mira la tabla de quesos que he creado en Kroon!',
        url: url
      }).then(() => {
        console.log('Tabla compartida con éxito');
      }).catch(err => {
        console.log('Error al compartir:', err);
      });
    } else {
      alert('Tu navegador no soporta la funcionalidad de compartir.');
    }
  },
    async procesarRespuestaGPT(respuesta) {
      // Asumiendo que la respuesta de GPT viene con ambas versiones o que puedes obtenerlas
      const ingredientesEnEspanol = {
        ...respuesta.ingredientes,
        // Si GPT devuelve "Red wine", guardamos "Vino tinto" directamente
      };

      // Cuando guardamos en Strapi, usamos la versión en español
      const datosParaGuardar = {
        uuid_imagen: this.uuid,
        ingredientes: ingredientesEnEspanol,
        // ... resto de datos ...
      };

      // Guardar en Strapi
      await this.guardarEnStrapi(datosParaGuardar);
    }

  },
  metaInfo() {
    return {
      title: 'Mi tabla de quesos Kroon',
      meta: [
        // Open Graph
        { property: 'og:title', content: 'Mi tabla de quesos Kroon' },
        { property: 'og:description', content: this.descripcion },
        { property: 'og:image', content: this.imagenUrl },
        { property: 'og:url', content: `${window.location.origin}/tabla/${this.uuidImagen}` },
        { property: 'og:type', content: 'website' },
        
        // Twitter Cards
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Mi tabla de quesos Kroon' },
        { name: 'twitter:description', content: this.descripcion },
        { name: 'twitter:image', content: this.imagenUrl }
      ]
    }
  }
};
</script>

<style scoped>

.sparkles-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.sparkles-icon-small{
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

/* Mantén tus estilos existentes */
.ingredientes-lista {
  text-align: left;
  margin-top: 20px;
  max-width: 80%;
  margin: 10px auto;
}

li.sugerencia {
  margin-bottom: 20px;
  line-height: 1.1;
}

a.recrear-link {
  top: 100px;
  position: absolute;
  z-index: 1000;
  left: 30px;
  color: white;
  text-decoration: none;
}

h1 {
  color: #f1c40f;
}

.coronas {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.coronas-icon {
  font-size: 30px;
  margin: 0 5px;
}

/* Enlace estilizado para recomendaciones */
.recomendaciones-link {
  font-size: 1.1em;
  color: #a8946b;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 20px;
}

.recomendaciones-link:hover {
  color: #f1c40f;
}

/* Spinner del botón */
.spinner-border {
  margin-right: 5px;
}

/* Estilo del modal */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(60, 60, 60, 0.7); /* Fondo oscuro */
  display: flex;
  border: 1px solid #444;
  justify-content: center;
  align-items: flex-start; /* Alineado hacia arriba */
  overflow-y: auto; /* Permite scroll */
  padding: 10px !important; /* Añade espacio para el scroll */
}

.modal-content {
  background-color: #181818; /* Fondo gris oscuro */
  color: #fff;
  padding: 10px !important;
  border-radius: 10px;
  border: 1px solid #000;
  width: 100%;
  position: relative;
}

.modal-body {
  overflow-y: auto; /* Scroll dentro del modal */
  text-align: left; /* Alineación a la izquierda */
  padding: 10px 10px;
}

/* Leyenda para imagen generada con IA */
.leyenda-ia {
  font-size: 0.8rem;
  margin-top: 0px;
  color: #ccc;
  text-align: center;
}

/* Título "¡Qué delicia!" */
.delicia-titulo {
  font-size: 2rem;
  color: #f1c40f;
  margin-top: 10px;
  text-align: center;
}

/* Subtítulo "Lleva esta tabla a la realidad" */
.delicia-subtitulo {
  font-size: 1rem;
  color: #f1f1f1;
  text-align: center;
  margin-bottom: 0px;
}

ol {
  text-align: left;
  padding-left: 20px;
}

ol li {
  margin-bottom: 0px;
}

/* Botón de cierre en forma de cruz "X" */
.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  background: none;
  border: none;
}

.close-button:hover {
  color: #ccc;
}

/* Estilo para la liga "Regenerar" */
a.recrear-link {
  display: block;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  text-align: left;
  margin-bottom: 0px;
  text-decoration: none;
  position: relative;
  top: 50px;
  left: 30px; 
  z-index: 1000;
}

.recrear-link:hover {
  text-decoration: underline;
}
</style>
